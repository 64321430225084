import axios from "axios";
axios.defaults.headers.common['x-api-key'] = "ws123";
axios.defaults.headers.common['accept-language'] = "ar";
import {EventBus} from "./event";

let constructAuthorizedRequest = () => {
   let token = localStorage.getItem("access-token");
   // const locale = JSON.parse(localStorage.getItem("isRTL")) === true ? "ar" : "en";
   let instance = axios.create({
      headers: {
         'Authorization': `Bearer ${token}`
      }
   });
   instance.interceptors.response.use((response) => {
      return response;
   }, (error) => {
     if (error.response.status === 401) {
       EventBus.$emit("unauthorized-request");
     }
     return Promise.reject(error);
   });
   return instance;
};

export default {
   constructAuthorizedRequest,
   axios
}