<template>
  <v-row justify="center" align="center">
    <v-col cols="12" class="text-center">
      <v-img
          class="mx-auto"
          max-width="1200"
          :src="require('../assets/img/empty-cart.webp')"
      ></v-img>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "emptyCart"
}
</script>

<style scoped>

</style>