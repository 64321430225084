<template>
  <v-app-bar
      app
      color="white"
  >
    <v-container>
      <div class="d-flex align-center">
        <v-img
            alt="Atlobha logo"
            class="shrink mr-2"
            contain
            src="../../assets/img/atlobha-logo.png"
            transition="scale-transition"
            width="120"
        />
        <v-spacer></v-spacer>
        <v-btn
            text
        >
          <v-badge
              :content="cart.length"
              :value="cart.length"
              color="amber lighten-1"
              overlap
          >
            <v-icon>mdi-cart</v-icon>
          </v-badge>
        </v-btn>
        <div class="mx-1"></div>
        <v-menu offset-y bottom right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                plain
                v-bind="attrs"
                v-on="on"
            >
              <v-icon>mdi-account</v-icon>
            </v-btn>
          </template>
          <v-list width="200">
            <template v-for="item in items">
              <v-list-item v-if="item.type === 'router'"
                           :key="item.label" link
                           :to="{name: item.routeName}"
              >
                <v-list-item-title>
                  {{ item.label }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <v-divider></v-divider>
            <template v-for="item in items">
              <v-list-item :key="item.label" v-if="item.type === 'anchor'"
                           link @click.prevent="getLoggedOut">
                <v-list-item-title class="red--text text--darken-4">
                  {{ item.label }}
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
      </div>

    </v-container>
  </v-app-bar>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "AppHeader",
  data() {
    return {
      items: [
        // {
        //   label: this.$t("routes.profile"),
        //   routeName: "profile",
        //   type: "router"
        // },
        // {
        //   label: this.$t("routes.address"),
        //   routeName: "address",
        //   type: "router"
        // },
        {
          label: this.$t("routes.orders_history"),
          routeName: "orders_history",
          type: "router"
        },
        {
          label: this.$t("labels.logout"),
          type: "anchor"
        },
      ]
    }
  },
  computed: {
    ...mapState({
      cart: state => state.cart
    })
  },
  methods: {
    ...mapActions({
      logout: "_auth/logout"
    }),
    ...mapMutations({
      emptyCart: "emptyCart"
    }),
    getLoggedOut() {
      this.logout().then(() => {
        this.emptyCart();
        this.$router.replace({name: "login"});
      }).catch(() => {

      });
    }
  }
}
</script>

<style>
.v-badge__badge {
  color: #000000 !important;
  font-weight: 700;
}
</style>